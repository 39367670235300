import React from 'react'
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import GatsbyImage from "gatsby-image";
import {MDXProvider} from "@mdx-js/react";
import components from '../components/mdx_components'
import {MDXRenderer} from "gatsby-plugin-mdx";
import {graphql} from "gatsby";
import SEO from "../components/seo";
import EventsCard from "../components/EventsCard";

const Page = ({data}) => {
  const events = data.events.nodes.filter(x => new Date(x.frontmatter.date) > new Date())

  return (
    <React.Fragment>
      <SEO title="Yoga"/>
      <Container maxWidth={"md"}>
        <Box my={1}>
          <Card>
            <GatsbyImage fluid={data.file.childImageSharp.fluid}/>
            <Box m={1}>
              <MDXProvider components={components}><MDXRenderer>{data.mdx.body}</MDXRenderer></MDXProvider>
            </Box>
          </Card>
        </Box>
        {events.length > 0 ? (
          <Box my={1}>
            <EventsCard events={events}/>
          </Box>
        ) : null}
      </Container>
    </React.Fragment>
  )
}

export const pageQuery = graphql`
    query {
        mdx(frontmatter: { title: { eq: "Yoga" } }) {
            body
        }
        file(relativePath: { eq: "images/yoga.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 882, maxHeight: 500) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        events: allMdx(sort: {fields: frontmatter___date, order: ASC}, filter: {slug: {regex: "/events/"}, frontmatter: {topic: {eq: "yoga"}}}) {
            nodes {
                id
                body
                frontmatter {
                    date(formatString: "DD MMMM, YYYY")
                    time: date(formatString: "HH:mm")
                    title
                    location
                    price
                }
            }
        }
    }
`

export default Page
